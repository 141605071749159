/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const googleAnalyticsId = "UA-23895753-4"

// const Matomo = require('@datapunt/matomo-tracker-react');
// var MatomoProvider = Matomo.MatomoProvider;

// const React = require("react")
// const instance = Matomo.createInstance({
//   urlBase: 'https://nuvolum.matomo.cloud/',
//   siteId: 1,
// })

// exports.wrapRootElement = ({ element }) => {
//   return (
//     <MatomoProvider value={instance}>
//       {element}
//     </MatomoProvider>
//   )
// }

function initGTMOnEvent(event) {
  initGTM()
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent) // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false
  }

  window.gtmDidInit = true // flag to ensure script does not get added to DOM more than once.

  var script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${googleAnalyticsId}`

  script.onload = function () {
    dataLayer.push({
      // ensure PageViews are always tracked
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
      "gtm.uniqueEventId": 0
    })
  }

  document.head.appendChild(script)
}

exports.onClientEntry = function () {
  document.onreadystatechange = function () {
    if (document.readyState !== "loading") setTimeout(initGTM, 1000)
  }

  document.addEventListener("scroll", initGTMOnEvent)
  document.addEventListener("mousemove", initGTMOnEvent)
  document.addEventListener("touchstart", initGTMOnEvent)
}
